/* =========================================
 Sections  
 =========================================*/

section {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    backface-visibility: hidden;
    &:not(.small-section) {
        min-height: 700px;
        padding: 100px 10px;
    }
    &.small-section {
        min-height: 360px;
    }
    .home &:nth-child(odd) {
        color: $color-white;
        .title {
            color: $color-white;
        }
    }
    .home &:nth-child(even) {
        color: $color-black;
        background: $color-white;
        .title {
            .display-4,
            h4 {
                @extend %gradientText;
            }
            p {
                color: $color-gray;
                -webkit-text-fill-color: $color-gray;
            }
        }
    }
}