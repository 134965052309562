/* =========================================
 FQA  Section
 =========================================*/

#fqa {
    #accordion {
        .card {
            margin: 0.7rem 0;
            /* Question's Title (Header)*/
            .card-header {
                padding: 0;
                position: relative;
                @extend %gradientText;
                cursor: pointer;
                overflow: hidden;
                text-transform: capitalize;
                h6 {
                    padding: .6rem 1.25rem;
                    font-size: 17px;
                    // &:before {
                    //     position: absolute;
                    //     top: 0;
                    //     width: 40px;
                    //     content: '';
                    //     background: color(gradient);
                    //     transform: rotate(45deg);
                    //     right: -29px;
                    //     border-radius: 13px;
                    //     height: 100%;
                    // }
                }
            }
            /* Answer */
            .card-body {
                position: relative;
                p {
                    font-size: 14px;
                    color: $color-gray;
                    font-weight: 500;
                }
            }
        }
    }
}